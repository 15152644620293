import { useTranslation } from "react-i18next";

const SignInWithGoogleButton = ({ loading, error, signInWithGoogle }) => {
  const { t } = useTranslation();

  return (
    <div>
      {error && <p>{error}</p>}
      <button
        onClick={signInWithGoogle}
        className="google-sign-in-button"
        disabled={loading}
      >
        {loading ? t("LOADING") : t("SIGN_IN_WITH_GOOGLE")}
      </button>
    </div>
  );
};

export default SignInWithGoogleButton;
