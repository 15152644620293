import DisplayFileContent from "components/DisplayFileContent";
import LanguageSwitcher from "components/LanguageSwitcher";
import UserAuth from "components/auth/UserAuth";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("APP_NAME")}</h1>
      <LanguageSwitcher />
      <UserAuth />
      <DisplayFileContent /> {/* Display the file content */}
    </div>
  );
};

export default Home;
