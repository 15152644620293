import { useTranslation } from "react-i18next";
import { useAuth } from "components/auth/AuthProvider";

const SignOutButton = () => {
  const { signOut } = useAuth();
  const { t } = useTranslation();

  return (
    <button onClick={signOut} className="sign-out-button">
      {t("SIGN_OUT")}
    </button>
  );
};

export default SignOutButton;
