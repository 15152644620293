import { useAuth } from "components/auth/AuthProvider"; // Import useAuth
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// This function fetches session details from the server to validate user login
async function fetchSessionFromServer() {
  const response = await fetch(
    "http://localhost:5001/notiscytodo/us-central1/auth/verify-session",
    {
      credentials: "include",
    }
  );
  if (!response.ok) {
    throw new Error("Session verification failed");
  }
  return response.json();
}

const LoginSuccess = () => {
  const navigate = useNavigate();
  const { updateSession } = useAuth(); // Make sure you implement this method in your AuthProvider

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await fetchSessionFromServer();
        updateSession(session); // Update session context or state
        console.log(
          "Successfully logged in with OAuth tokens stored securely."
        );
        navigate("/"); // Redirect to user profile or dashboard
      } catch (error) {
        console.error("Failed to fetch user session:", error);
        navigate("/"); // Redirect back to login on failure
      }
    };

    fetchSession();
  }, [navigate, updateSession]);

  return <div>Loading your profile...</div>;
};

export default LoginSuccess;
