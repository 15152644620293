import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { app } from "firebaseConfig";
import { createContext, useContext, useEffect, useState } from "react";

const auth = getAuth(app);

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null); // Initialize accessToken here

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      if (user) {
        setUser(user);
        setError(null);
        // Handle the token right after authentication
        user.getIdToken().then((token) => {
          setAccessToken(token); // Assuming you want to use Firebase ID token for Google Drive API
        });
      } else {
        setUser(null);
        setAccessToken(null); // Clear access token when user signs out or is not signed in
      }
    });
    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    setError(null);
    setLoading(true);
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/drive.readonly");
    provider.addScope("https://www.googleapis.com/auth/drive.file");
    try {
      const result = await signInWithPopup(auth, provider);
      // Access the OAuth token directly for Google APIs
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken; // This is the OAuth2 access token you need
      setAccessToken(token);
      setUser(result.user);
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const signInWithEmail = async (email, password) => {
    setError(null);
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Error signing in with email:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
      setError(error.message);
    }
    setLoading(false);
    setUser(null);
    setAccessToken(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        signInWithGoogle,
        signInWithEmail,
        signOut,
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
