import { AuthContext } from "components/auth/AuthProvider";
import { useContext, useEffect, useState } from "react";

const useGoogleDriveSetup = () => {
  const { accessToken } = useContext(AuthContext);
  const [fileContent, setFileContent] = useState(null);

  useEffect(() => {
    const getFile = async () => {
      if (!accessToken) {
        console.log("No access token available.");
        return;
      }

      try {
        console.log(
          "Fetching files from Google Drive with access token:",
          accessToken
        );
        const response = await fetch(
          "https://www.googleapis.com/drive/v3/files?pageSize=10&fields=nextPageToken,files(id,name,mimeType)",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        console.log("data", data);

        if (response.ok && data.files.length > 0) {
          console.log("Files found:", data.files);
          const fileId = data.files[0].id;
          const fileResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "text/plain",
              },
            }
          );
          const fileBody = await fileResponse.text();
          setFileContent(fileBody);
        } else {
          console.log("No files found or error fetching files:", data.error);
        }
      } catch (error) {
        console.error("Error accessing Google Drive:", error);
      }
    };

    getFile();
  }, [accessToken]);

  return { fileContent };
};

export default useGoogleDriveSetup;
