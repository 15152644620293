import { useAuth } from "components/auth/AuthProvider";
import SignInWithGoogleButton from "components/auth/SignInWithGoogleButton";

const SignInWithGoogle = () => {
  const { signInWithGoogle, loading, error } = useAuth();

  return (
    <SignInWithGoogleButton
      loading={loading}
      error={error}
      signInWithGoogle={signInWithGoogle}
    />
  );
};

export default SignInWithGoogle;
