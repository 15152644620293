import { useAuth } from "components/auth/AuthProvider";
import SignInWithEmailForm from "components/auth/SignInWithEmailForm";

const SignInWithEmail = () => {
  const { signInWithEmail, loading, error } = useAuth(); // Use signInWithEmail from context

  return (
    <SignInWithEmailForm
      signInWithEmail={signInWithEmail}
      loading={loading}
      error={error} // Pass error and loading state from AuthProvider
    />
  );
};

export default SignInWithEmail;
