import { useState } from "react";
import { useTranslation } from "react-i18next";

const SignInWithEmailForm = ({ signInWithEmail, loading, error }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = (event) => {
    event.preventDefault();
    signInWithEmail(email, password).catch((error) => {
      // Handle errors if you want to do something specific here
      console.error("Error signing in with email:", error);
    });
  };

  return (
    <form onSubmit={handleSignIn} className="email-sign-in-form">
      <input
        type="email"
        placeholder={t("EMAIL")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="email-input"
      />
      <input
        type="password"
        placeholder={t("PASSWORD")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="password-input"
      />
      <button type="submit" className="sign-in-button">
        {t("SIGN_IN_WITH_EMAIL")}
      </button>
      {loading && <p>{t("LOADING")}</p>}
      {error && (
        <p>
          {t("SIGN_IN_FAILED")}: {error}
        </p>
      )}
    </form>
  );
};

export default SignInWithEmailForm;
