import { useAuth } from "components/auth/AuthProvider";
import { useTranslation } from "react-i18next";
import SignInWithEmail from "components/auth/SignInWithEmail";
import SignInWithGoogle from "components/auth/SignInWithGoogle";
import SignOutButton from "components/auth/SignOutButton";

const UserAuth = () => {
  const { user, loading } = useAuth();
  const { t } = useTranslation();

  const getGreetingName = () => {
    return user.displayName || user.email || t("CUSTOMER");
  };

  if (loading) {
    return <div>{t("LOADING")}</div>;
  }

  if (user) {
    return (
      <div className="user-authenticated">
        <p>{t("WELCOME_USER", { name: getGreetingName() })}</p>
        <SignOutButton />
      </div>
    );
  } else {
    return (
      <div className="authentication-methods">
        <SignInWithGoogle />
        <SignInWithEmail />
      </div>
    );
  }
};

export default UserAuth;
