import { AuthContext } from "components/auth/AuthProvider";
import useGoogleDriveSetup from "components/auth/useGoogleDriveSetup";
import { useContext } from "react";

const DisplayFileContent = () => {
  const { loading, accessToken } = useContext(AuthContext);
  const { fileContent } = useGoogleDriveSetup();

  if (!accessToken) return <div>Please log in to view content.</div>;
  if (loading) return <div>Loading...</div>;
  return (
    <div>
      <h2>File Content:</h2>
      <pre>{fileContent || "No file content available."}</pre>
    </div>
  );
};

export default DisplayFileContent;
